import AreaLayer from "./AreaLayer";
import LineLayer from "./LineLayer";
import ZeroLineLayer from "./ZeroLineLayer";
import { linearGradientDef } from "@nivo/core";

export const marginChartProps = {
  layers: [
    "grid",
    "axes",
    ZeroLineLayer,
    AreaLayer,
    LineLayer,
    "crosshair",
    "slices",
  ],
  defs: [
    linearGradientDef("positiveGradient", [
      { offset: 0, color: "#5bce53", opacity: 0.4 },
      { offset: 100, color: "#5bce53", opacity: 0 },
    ]),
    linearGradientDef("negativeGradient", [
      { offset: 0, color: "#FF4062", opacity: 0 },
      { offset: 100, color: "#FF4062", opacity: 0.4 },
    ]),
  ],
  axisLeft: {
    format: (v) => {
      return `${v}%`;
    },
    tickValues: 8,
  },
  chartFormat: (data) => {
    return [{ id: "Margin", data: [...data.current] }];
  },
  enableArea: true,
};
