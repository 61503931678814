import RightNav from "./rightnav/RightNav";
import LeftNav from "./leftnav/LeftNav";

function Navbar() {
  return (
    <>
      <LeftNav />
      <RightNav />
    </>
  );
}

export default Navbar;
